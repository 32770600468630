<template>
  <div class="mine-page">
    <el-table
      class="data-table"
      :data="tableData"
      border
      :row-class-name="tableRowClassName">
      <el-table-column
        label="DATE"
        prop="createTime"
        width="120">
      </el-table-column>
      <el-table-column
          label="ORDER"
          prop="code"
          width="150">
      </el-table-column>
      <el-table-column
        label="AMOUNT"
        prop="amount"
        align="right"
        width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.amount">${{scope.row.amount | addCommas}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="TYPE"
        prop="planType"
        align="center"
        width="100">
      </el-table-column>
      <el-table-column
        label="PRODUCT"
        prop="productTitle"
        align="center"
        width="90">
      </el-table-column>
<!--      <el-table-column-->
<!--        label="PROXY INFO"-->
<!--        prop="proxyInfo"-->
<!--        align="center">-->
<!--      </el-table-column>-->
      <el-table-column
        label="STATUS"
        prop="status"
        align="center"
        width="100">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == '-1'" type="warning" size="small">Cancelled</el-tag>
          <el-tag v-else-if="scope.row.status == '0'" size="small">Pending</el-tag>
          <el-tag v-else-if="scope.row.status == '1'" type="success" size="small">Paid</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="layout-pag"
      :layout="pagination.layout"
      :current-page.sync="pagination.currentPage"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.pageSizes"
      :total="pagination.total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange">
    </el-pagination>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { Pagination } from '@/mixins/pagination'
import { getOrders } from '@/apis/mine'

export default {
  mixins: [ Pagination ],
  components: {},
  props: {},
  data () {
    return {
      tableData: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    ...mapMutations(['setLoading']),
    getList() {
      this.setLoading({
        state: true,
        text: ''
      })
      getOrders({
        page: this.pagination.currentPage,
        total: this.pagination.pageSize
      }, (res) => {
        // console.log(res)
        this.tableData = res
        this.pagination.total = res.length
        this.setLoading({
          state: false,
          text: ''
        })
      }, (err) => {
        // console.log(err)
        this.setLoading({
          state: false,
          text: ''
        })
      })
    },
    tableRowClassName({row, rowIndex}) {
      // console.log(rowIndex % 2)
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      }
      return '';
    }
  },
  created () {
    this.handleSearch()
  },
  mounted () {},
  destroyed () {}
}
</script>
