export const Pagination = {
  data() {
    return {
      pagination: {
        layout: 'total, sizes, prev, pager, next',
        currentPage: 1,
        pageSize: 50,
        pageSizes: [50, 100, 200, 500],
        total: 0
      }
    }
  },
  methods: {
    handleSearch: function() {
      this.pagination.currentPage = 1;
      this.pagination.pageSize    = 50;
      this.getList();
    },
    handleCurrentChange: function(val) {
      this.pagination.currentPage = val;
      this.getList();
    },
    handleSizeChange: function(val) {
      this.pagination.currentPage = 1;
      this.pagination.pageSize    = val;
      this.getList();
    }
  }
}